<template>
  <div>
    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex flex-column justify-content-center">
      <div class="container" data-aos="zoom-in" data-aos-delay="100">
        <p><span role="img" aria-label="waving">👋</span>&nbsp;<span>Hey! I'm</span></p>
        <h1>Nigel Yong Sao Young</h1>
        <vue-typed-js style="display: block" :typeSpeed="100" :startDelay="3000" :backDelay="4000" :loopCount="3" :loop="true" :strings="['Tech', 'Problem Solving', 'Coding', 'Life']">
          <p>I love <span class="typing"></span></p>
        </vue-typed-js>
        <div class="social-links">
          <a href="https://www.facebook.com/niyonx/" class="facebook"><i class="bx bxl-facebook"></i></a>
          <a href="https://www.instagram.com/niyonx/" class="instagram"><i class="bx bxl-instagram"></i></a>
          <a href="https://www.linkedin.com/in/niyonx/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
          <a href="https://github.com/niyonx" class="github"><i class="bx bxl-github"></i></a>
        </div>
      </div>

      <div class="arrow">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </section><!-- End Hero -->
  </div>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    
  },
  data () {
  return {
    publicPath: process.env.BASE_URL
  }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color: #42b983;
} 
</style>
<template>
  <!-- ======= Resume Section ======= -->
  <section id="resume" class="resume">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Resume <span role="img" aria-label="work">💼</span></h2>
        <p></p>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <h3 class="resume-title">Summary</h3>
          <div class="resume-item pb-0">
            <h4>Nigel Yong Sao Young</h4>
            <p><em>Tech enthusiast</em></p>
            <ul>
              <li>Montreal, Canada</li>
              <li>hey[at]nigelyong[.]com</li>
            </ul>
          </div>

          <h3 class="resume-title">Education</h3>
          <div class="resume-item">
            <h4>BSC in Computer Science with Honour, 2021</h4>
            <h5>2018 - 2021</h5>
            <p><em>Concordia University, Montreal</em></p>
            <p>Courses: Data Structures and Algorithms, Data Analytics, Machine Learning, Artificial Intelligence,
Operating Systems, Design and Analysis of Algorithms, Pattern Recognition, Databases, OOP.</p>
          </div>
          <div class="resume-item">
            <h4>Cambridge A level</h4>
            <h5>Jan 2015 - Dec 2017</h5>
            <p><em>College du Saint Esprit, Mauritius</em></p>
            <p>A* in Mathematics, Physics and Computer Science.</p>
            <p>Ranked 3rd out of 966 students in Computer Science at National Level.</p>
          </div>
          <h3 class="resume-title">Awards</h3>
          <div class="resume-item">
            <h4>1st place in Category + 3rd Best Overall</h4>
            <p><em>Hackatown 2021</em></p>
            <p>Awarded 1st place in Agriculture and
Food category and 3rd Best Overall.</p>
          </div>
          <div class="resume-item">
            <h4>SAP Challenge winner</h4>
            <p><em>ConUHacks V Hackathon</em></p>
            <p>Awarded for the best usage of SAP Cloud Technology.</p>
          </div>
          <div class="resume-item">
            <h4>Best Project</h4>
            <p><em>TOHacks 2021</em></p>
            <p>Awarded 1000$ for winning the Intact Challenge - Improve Customer Satisfaction</p>
          </div>
          <div class="resume-item">
            <h4>Concordia University Entrance Scholarship</h4>
            <p><em>Concordia University</em></p>
            <p>Awarded a total of 15k CAD.</p>
          </div>
        </div>
        <div class="col-lg-6">
          <h3 class="resume-title">Professional Experience</h3>
          <div class="resume-item">
            <h4>Founder & CTO</h4>
            <h5>Sep 2021 - Oct 2022</h5>
            <p><em>Categen Ventures</em></p>
            <ul>
              <li>Founded <a href="https://pikapoint.io">pikapoint.io</a>, an online food ordering storefront hosting 100+ restaurant websites across Quebec & Ontario with 150k+ cash flow per month.</li>
              <li>Integrated and partnered with Doordash, Stripe, Bambora, Eva Coop and Talad POS</li>
              <li>Optimized and set up a scalable and distributed infrastructure on AWS with ALB and auto-scaling.</li>
              <li>Published PikaPoint Staff on the <a href="https://apps.apple.com/ca/app/pikapoint-staff/id1616012964">App</a> and <a href="https://play.google.com/store/apps/details?id=com.categen.pikapoint_staff&hl=en_US&gl=US">Play store</a>, allowing staff to manage orders in real-time.</li>
              <li>Mentored and guided by experts. Led a team of 6 including interns, developers, graphic designers and outsource teams with the Agile methodology.</li>
              <li>Laravel PHP, Flutter, Nuxt 3, AWS (EC2, S3, ALB, RDS), Cloudflare, MySQL, GCP, Docker, etc.</li>
            </ul>
          </div>
          <div class="resume-item">
            <h4>Technology Analyst Co-op</h4>
            <h5>Jan 2021 - Apr 2021</h5>
            <p><em>Morgan Stanley</em></p>
            <ul>
              <li>Led the <a href="https://lightstreamer.com/">LightStreamer</a> (LS) upgrade migration project, which provides real-time pub/sub market data and content-based transfer.</li>
              <li>Onboarded, tested and deployed the latest version (LS7) to upgrade 5000+ active LS instances.</li>
              <li>Developed new LS adapters with backward compatibility (LS6 and LS5) for developers to upgrade without updating any code, saving developers time and effort.</li>
              <li>Java, Angular, Jenkins, JUnit, LightStreamer.</li>
            </ul>
          </div>
          <div class="resume-item">
            <h4>Technology Analyst Co-op</h4>
            <h5>Jan 2020 - Apr 2020</h5>
            <p><em>Morgan Stanley</em></p>
            <ul>
              <li>Implemented the Blastmail metrics reporting project, providing visual insights on a huge database of data collected from sending bulk emails.</li>
              <li>Deployed reporting web pages to production for 100+ MS teams.</li>
              <li>Created a metrics REST API with 20+ endpoints from planning to deployment.</li>
              <li>Angular, PrimeNG, Python Flask, DB2, Jenkins.</li>
            </ul>
          </div>
          <div class="resume-item">
            <h4>Software Development Co-op</h4>
            <h5>May 2019 - Aug 2019</h5>
            <p><em>Bombardier Aviation</em></p>
            <ul>
              <li>Launched a new Knowledge Management internal site, a centralized platform for Bombardier Employees to publish and access lessons learned and engineering documents.</li>
              <li>Researched the best infrastructure options for organizing and storing documents.</li>
              <li>Created UI elements for seamless navigation. Created a search page with custom filtering for quick document retrieval.</li>
              <li>Microsoft SharePoint, HTML, CSS, JS, Bootstrap</li>
            </ul>
          </div>
          <div class="resume-item">
            <h4>Full-stack Developer Intern</h4>
            <h5>Mar 2018 - June 2018</h5>
            <p><em>CyberNaptics Ltd</em></p>
            <ul>
              <li>Created and launched a functional and user-friendly Leave Management System from scratch, eventually adopted by 40+ employees.</li>
              <li>Built a data entry website for a meteorological station to most efficiently input data.</li>
              <li>HTML, CSS, JS, jQuery, Bootstrap, PHP, MySQL, pgSQL.</li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </section><!-- End Resume Section -->
</template>

<script>
export default {
  name: 'Resume',
  props: {
    
  },
  data () {
  return {
    publicPath: process.env.BASE_URL
  }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color: #42b983;
} 
</style>